import React, {Component} from 'react';

import Quiz from './Quiz/Quiz';
import Intro from './Intro/Intro';
import Forms from './Forms/Forms';
import Email from './Email/Email';
import Choose from './Choose/Choose';
import FormsCompany from './FormsCompany/FormsCompany';
import IntroCompany from './IntroCompany/IntroCompany';

//Import query string library
import qs from 'qs';

//API lib
import api from "../../api";

//Style
import './QuizController.css';

class QuizController extends Component {

	constructor(props){
		super(props);

		this.initialState = {

			/*
				The quiz can be of three type: 
					"unknown" (UX1), "known" (UX1), or "company" (UX2).
					
					- unknown: user not logged in
					- known: user logged in
					- company: user not logged. coming from quizlink

				The quiz consist of different phases. Each phase is implemented with a separated React component.
				The logic is completely implemented here.
				
				"unknown" phases:
					0: Choose - Choose the quiz to do
					1: Intro - Ask user data (sesso, eta)
					2: Quiz - Questions/Answers
					3: Forms - Ask email, telefono, cap_residenza, company name, company cap_residenza
					4: Email - Inform user that we sent an email, and verify
					-> Results

				"known" phases:
					0: Choose - Choose the quiz to do
					1: Quiz - Question/Answers
					-> Results

				"company" phases:
					0: FormsCompany - Ask user data (sesso, eta, email, telefono, cap_residenza)
					1: Quiz - Questions/Answers
					2: FormCompany
					3: Email - Inform user that we sent an email, and verify
					-> Results
			*/

			type: "", //unknown, known, company
			phase: 0, //0,1,2,3,4

			quizzes: null, //All the available quizzes

			//User information
			nome: "",
			cognome: "",
			email: "",
			repeatEmail: "",
			password: "",
			repeatPassword: "",
			sesso: "", //"maschio", "femmina"
			status_occupazionale: "", //"occupato", "disoccupato"
			eta: "", //int
			telefono: "", //int
			cap_residenza: "", //User cap of residence
			privacy: false, //true or false

			quizzesCompleted: null, //List of quizzes already done

			//Company information (optional)
			nome_azienda: "", //Name of the company
			cap_azienda: "", //cap of the company

			//Quiz status
			quiz: null, //the entire quiz object received from API endpoint
			
			//Company Link (Only for company quiz)
			link: null,

			/*
			answers:
				Array that contain the user answers.
				It is an array of array (areas). Example: 
				answers: [
					[{"question_id": 1,"answer": 2}, {"question_id": 2,"answer": 2}, ...], --> AREA 1
					[{"question_id": 3,"answer": 2}, {"question_id": 4,"answer": 2}, ...], --> AREA 2
					[{"question_id": 4,"answer": 2}, {"question_id": 6,"answer": 3}, ...], --> AREA 3
					[{"question_id": 7,"answer": 2}, {"question_id": 8,"answer": 1}, ...], --> AREA 4
					[{"question_id": 9,"answer": 2}, {"question_id": 10,"answer": 1}, ...], --> AREA 5
				]

			*/
			answers: [],

			//Choose Component State:

			//Quiz Component State:
			areaindex: 0, //Index of the current area displayed
			questionindex: 0, //Index of the current question displayed
			displayAlert: false, //If true, show the alert: "Rispondi alla domanda per continuare"
			totquestions: null, //the total number of questions in the quiz (progress calculation)
			showModal: false, //Question modal state:

			//Form Component State:
			errorMessage: "",
			displayError: false,

			//Email Component State:
			pin: "",
			verifyLoading: false
		};

		this.state = this.initialState; //Store the initial state, so it can be restored
	}

	componentDidMount(){

		//Select the type of the quiz:
		//Get the URL params
		if(this.props.location.search){
			//Find the quizlink url params
			const link = this.props.location.search.split("=")[1];
			if (link) {
				//Type: company
				this.setState({
					type: "company",
					link: link
				});
				this.loadCompanyQuizzes(link);
			}
		} else {
			if (this.props.appstate.user) {
				//Type: known
				this.setState({
					type: "known"
				});
				this.loadQuizzes();
			} else {
				//Type: unknown
				this.setState({
					type: "unknown"
				});
				this.loadQuizzes();
			}
		}
	}

	loadQuizzes = () => {

		//Load all available quizzes
		//Get the quiz from API
		api.get('/quizzes/get', {})
	    .then(res1 => {

	    	//If user logged, load also completed quizzes
		    if (this.props.appstate.user) {
			    //Get the quiz completed from API
			    api.get('quizzes/user', {
			      params: {token: this.props.appstate.token}
			    })
			    .then(res2 => {
			      this.setState({
				    quizzes: res1.data.data,
			        quizzesCompleted: res2.data.data.quizzes
			      });
			    });
		    } else {
		    	this.setState({
				    quizzes: res1.data.data
				});
		    }
	    });
	}

	loadCompanyQuizzes = (link) => {

		//Load all available quizzes
		//Get the quiz from API
		const post_data = {"link": link};

		api.post('quizzes/fromlink', qs.stringify(post_data))
	    .then(res => {
	   		console.log(res);

	   		//Get the quiz obj
			const quiz = res.data.data.quiz;

			//Create the answers object, from the quiz
			let answers = [];
			for (var i = 0; i < quiz.areas.length; i++) {
				answers.push(
					new Array(quiz.areas[i].questions.length)
				)
			}

			//Calculation of the total number of questions:
			let totquestions = 0;
			for (i = 0; i < quiz.areas.length; i++) {
				totquestions += quiz.areas[i].questions.length;
			}

			//Store these variables in the state
			this.setState({
				quiz: quiz,
				answers: answers,
				totquestions: totquestions,
				nome_azienda: res.data.data.company.ragione_sociale
			});
	    });
	}


	//----------------------------------------------------------------------
	//------------------------------------------------------ PHASE 0 - Choose
	//----------------------------------------------------------------------
	/*
		selectQuiz
		-------------------------
	*/
	selectQuiz = (quiz_index) => {
		
		//Get the quiz obj
		const quiz = this.state.quizzes[quiz_index];

		//Create the answers object, from the quiz
		let answers = [];
		for (var i = 0; i < quiz.areas.length; i++) {
			answers.push(
				new Array(quiz.areas[i].questions.length)
			)
		}

		//Calculation of the total number of questions:
		let totquestions = 0;
		for (i = 0; i < quiz.areas.length; i++) {
			totquestions += quiz.areas[i].questions.length;
		}

		//Store these variables in the state
		this.setState({
			quiz: quiz,
			answers: answers,
			totquestions: totquestions,
			phase: 1
		});
	}

	//----------------------------------------------------------------------
	//------------------------------------------------------ PHASE 1 - Intro
	//----------------------------------------------------------------------

	/*
		startQuiz
		-------------------------
	*/
	startQuiz = () => {
		const oldphase = this.state.phase;
		this.setState({
			phase: oldphase+1
		});
	}

	/*
		selectChanged
		-------------------------
	*/
	selectChanged = (e) => {
		this.setState({
	    	[e.target.id]: e.target.value
	    });
	}

	/*
		etaChanged
		-------------------------
	*/
	etaChanged = (e) => {
		this.setState({
	    	eta: e.target.value
	    });
	}


	//----------------------------------------------------------------------
	//------------------------------------------------------- PHASE 2 - Quiz
	//----------------------------------------------------------------------

	/*
		handleClickNext
		-------------------------
		Respond at user Next click
		Calculate index of the next area and next question
	*/
	handleClickNext = () => {
		
		//Check if we reach the end of the quiz
		if ((this.state.areaindex === this.state.quiz.areas.length - 1)&&
			(this.state.questionindex === (this.state.quiz.areas[this.state.areaindex].questions.length - 1))){
			
			//End of the quiz reached
			const oldphase = this.state.phase;
			this.setState({
				phase: oldphase+1
			});

		} else {

			//Check if current question has been answered
			
			if (this.state.answers[this.state.areaindex][this.state.questionindex] == null) {
				//Not answered
				this.setState({
					displayAlert: true
				});
			} else {

				//Indexes of next area and question:
				let next_questionindex = null;
				let next_areaindex = null;

				//Check if we reach the end of the area:
				if (this.state.questionindex === this.state.quiz.areas[this.state.areaindex].questions.length - 1) {
					//Next area
					next_areaindex = this.state.areaindex + 1;
					next_questionindex = 0;
				} else {
					//Same area
					next_questionindex = this.state.questionindex + 1;
					next_areaindex = this.state.areaindex;
				}	

				//Upate indexes
				this.setState({
					areaindex: next_areaindex,
					questionindex: next_questionindex,
					displayAlert: false
				});
			}
		}
	}

	/*
		handleClickBack
		-------------------------
		Respond at user Back click
		Calculate index of the next area and next question
	*/
	handleClickBack = () => {

		//Check we are not at the beginning of the quiz
		if (!((this.state.areaindex === 0)&&(this.state.questionindex === 0))) {

			//Indexes of next area and question:
			let next_questionindex = null;
			let next_areaindex = null;

			//Check if we are at the beginning of the area:
			if (this.state.questionindex === 0) {
				//Beginning of area, change it
				next_questionindex =  this.state.quiz.areas[this.state.areaindex - 1].questions.length - 1;
				next_areaindex = this.state.areaindex - 1;
			} else {
				//Continue in same area
				next_areaindex = this.state.areaindex;
				next_questionindex = this.state.questionindex - 1;
			}
			//Update indexes
			this.setState({
				areaindex: next_areaindex,
				questionindex: next_questionindex,
				displayAlert: false
			});
		}
	}

	/*
		handleClickAnswer
		-------------------------
		Respond at user answer click
	*/
	handleClickAnswer = (event, question_id) => {

		event.preventDefault();

		//Create a copy of answers
		if (this.state.answers) {

			//Create a copy of the object
			var newanswers = this.state.answers.slice();

			//Get and store the users response
			newanswers[this.state.areaindex][this.state.questionindex] = {
				"question_id": question_id,
				"answer": parseInt(event.target.value)
			};

			//Update results state
			this.setState({
				answers: newanswers
			});
		}

		//After 500ms go to the next question
		//NB: This cause rerender
		setTimeout(() => { 
			this.handleClickNext();
		}, 500);
	}

	/*
		openModal
		-------------------------
		Open question modal
	*/
	openModal = () => {
		this.setState({
			showModal: true
		});
	}

	/*
		closeModal
		-------------------------
		Close question modal
	*/
	closeModal = () => {
		this.setState({
			showModal: false
		});
	}

	//----------------------------------------------------------------------
	//------------------------------------------------------ PHASE 3 - Forms
	//----------------------------------------------------------------------

	handleChangeForm = (e) => {

		const target = e.target;
	    const value =
	      target.type === 'checkbox'
	        ? target.checked
	        : target.value;
	    const id = target.id;

	    this.setState({
	      	[id]: value,
	     	displayError: false,
			errorMessage: ""
	    });
	}

	//Quiz completed
	handleSubmitForms = (e) => {
		e.preventDefault();
		if (this.state.type === "unknown") {

			//Check fields:
			//Check for form errors:
		    if (!validateEmail(this.state.email)) {
		    	this.setState({
		    		email: "",
		    		repeatEmail: "",
		    		displayError: true,
		    		errorMessage: "Email non valida. Riprova."
		    	});

		    } else if (this.state.email !== this.state.repeatEmail) {
		    	this.setState({
		    		email: "",
		    		repeatEmail: "",
		    		displayError: true,
		    		errorMessage: "Email e Ripeti Email non corrispondono. Riprova."
		    	});
		    } else if (this.state.password !== this.state.repeatPassword) {
		    	this.setState({
		    		password: "",
		    		repeatPassword: "",
		    		displayError: true,
		    		errorMessage: "Password e Ripeti Password non corrispondono. Riprova."
		    	});
		    } else {

				const post_data = {
				    "user": {
						"nome": this.state.nome,
						"cognome": this.state.cognome,
						"email": this.state.email,
						"password": this.state.password
					},
					"employee_quiz": {
						"eta": parseInt(this.state.eta),
						"status_occupazionale": this.state.status_occupazionale
					},
					"employee": {
						"sesso": this.state.sesso,
						"telefono": parseInt(this.state.telefono),
						"cap_residenza": parseInt(this.state.cap_residenza),
						"nome_azienda": this.state.nome_azienda,
						"cap_azienda": parseInt(this.state.cap_azienda)
					},
					"quiz_id": this.state.quiz.id,
					"answers": [].concat.apply([], this.state.answers)
			    };

				//Submit Quiz to backend
				api.post('answers/save', qs.stringify(post_data))
			    .then(res => {

			    	if (res.status===200) {
			    		//Check for data error
			    		if (res.data.error) {
			    			alert("Errore Invio Dati. Riprova o conttatta il supporto.");
			    			this.props.history.replace('/');
			    		} else {
			    			this.setState({
						    	phase: 4 //go to email phase
						    });
			    		}
			    	}
			    	
			    });
			}

		} else if (this.state.type === "known") {

		} else if (this.state.type === "company") {

			//Check fields:
			//Check for form errors:
		    if (!validateEmail(this.state.email)) {
		    	this.setState({
		    		email: "",
		    		repeatEmail: "",
		    		displayError: true,
		    		errorMessage: "Email non valida. Riprova."
		    	});

		    } else if (this.state.email !== this.state.repeatEmail) {
		    	this.setState({
		    		email: "",
		    		repeatEmail: "",
		    		displayError: true,
		    		errorMessage: "Email e Ripeti Email non corrispondono. Riprova."
		    	});
		    } else if (this.state.password !== this.state.repeatPassword) {
		    	this.setState({
		    		password: "",
		    		repeatPassword: "",
		    		displayError: true,
		    		errorMessage: "Password e Ripeti Password non corrispondono. Riprova."
		    	});
		    } else {

				const post_data = {
					"link": this.state.link,
				    "user": {
						"nome": this.state.nome,
						"cognome": this.state.cognome,
						"email": this.state.email,
						"password": this.state.password
					},
					"employee_quiz": {
						"eta": "",
						"status_occupazionale": ""
					},
					"employee": {
						"sesso": "",
						"telefono": "",
						"cap_residenza": "",
						"nome_azienda": this.state.nome_azienda,
						"cap_azienda": ""
					},
					"quiz_id": this.state.quiz.id,
					"answers": [].concat.apply([], this.state.answers)
			    };

				//Submit Quiz to backend
				api.post('answers/save', qs.stringify(post_data))
			    .then(res => {

			    	if (res.status===200) {
			    		//Check for data error
			    		if (res.data.error) {
			    			alert("Errore Invio Dati. Riprova o conttatta il supporto.");
			    			this.props.history.replace('/');
			    		} else {
			    			//Increment phase
			    			const oldphase = this.state.phase;
			    			this.setState({
						    	phase: oldphase+1
						    });
			    		}
			    	}
			    	
			    });
			}
		}
	}


	//----------------------------------------------------------------------
	//------------------------------------------------------ PHASE 4 - Email
	//----------------------------------------------------------------------

	handleVerifyPin = () => {

		this.setState({
			verifyLoading: true
		});

		//Call App.js verifyPin function
		this.props.verifyPin(this.state.pin, success => {
			//If success, redirect to results
			if (success) {
				this.props.history.replace('risultati?quiz_id=' + this.state.quiz.id);
			} else {
				this.setState({
		            displayError: true,
		            verifyLoading: false,
		            errorMessage: "Errore durante la verifica. Riprova"
		        });
			}
		});
	}


	//----------------------------------------------------------------------
	//--------------------------------------------------------------- render
	//----------------------------------------------------------------------

	render(){

		let quizPhase = null;
		if (this.state.type === "unknown") {
			if (this.state.phase === 0) {
				quizPhase = (
					<Choose 
						type={this.state.type}
						quizzes={this.state.quizzes}
						selectQuiz={this.selectQuiz} />
				);
			}
			else if (this.state.phase === 1) {
				quizPhase = (
					<Intro 
						sesso={this.state.sesso}
						eta={this.state.eta}
						status_occupazionale={this.state.status_occupazionale}

						etaChanged={this.etaChanged}
						selectChanged={this.selectChanged} 
						startQuiz={this.startQuiz} />
				);
			} else if (this.state.phase === 2) {
				quizPhase = (
					<Quiz 
						areaindex={this.state.areaindex}
						questionindex={this.state.questionindex}
						quiz={this.state.quiz}
						answers={this.state.answers}
						totquestions={this.state.totquestions}
						showModal={this.state.showModal}
						handleClickNext={this.handleClickNext}
						handleClickBack={this.handleClickBack}
						handleClickAnswer={this.handleClickAnswer}
						
						openModal={this.openModal}
						closeModal={this.closeModal}
						displayAlert={this.state.displayAlert}
					/>);
			} else if (this.state.phase === 3) {
				quizPhase = (
					<Forms 
						nome={this.state.nome}
						cognome={this.state.cognome}
						email={this.state.email}
						repeatEmail={this.state.repeatEmail}
						password={this.state.password}
						repeatPassword={this.state.repeatPassword}
						privacy={this.state.privacy}

						telefono={this.state.telefono}
						cap_residenza={this.state.cap_residenza}
						nome_azienda={this.state.nome_azienda}
						cap_azienda={this.state.cap_azienda}
						status_occupazionale={this.state.status_occupazionale}

						handleChangeForm={this.handleChangeForm}
						handleSubmitForms={this.handleSubmitForms}
						displayError={this.state.displayError}
						errorMessage={this.state.errorMessage}
					/>);
			} else if (this.state.phase === 4) {
				quizPhase = (
					<Email 
						email={this.state.email}
						pin={this.state.pin}
						verifyLoading={this.state.verifyLoading}

						handleChangeForm={this.handleChangeForm}
						handleVerifyPin={this.handleVerifyPin}
						displayError={this.state.displayError}
						errorMessage={this.state.errorMessage}/>
				);
			}
		} else if (this.state.type === "known"){
			if (this.state.phase === 0) {
				quizPhase = (
					<Choose 
						type={this.state.type}
						quizzes={this.state.quizzes}
						quizzesCompleted={this.state.quizzesCompleted}
						selectQuiz={this.selectQuiz} />
				);
			} else if (this.state.phase === 1) {
				quizPhase = (
					<Quiz 
						areaindex={this.state.areaindex}
						questionindex={this.state.questionindex}
						quiz={this.state.quiz}
						answers={this.state.answers}
						totquestions={this.state.totquestions}
						showModal={this.state.showModal}

						handleClickNext={this.handleClickNext}
						handleClickBack={this.handleClickBack}
						handleClickAnswer={this.handleClickAnswer}
						
						openModal={this.openModal}
						closeModal={this.closeModal}
						displayAlert={this.state.displayAlert}
					/>);
			}
		} else if (this.state.type === "company"){
			if (this.state.phase === 0) {
				quizPhase = (
					<IntroCompany 

						nome_azienda={this.state.nome_azienda}
						startQuiz={this.startQuiz} />
				);
			} else if (this.state.phase === 1) {
				quizPhase = (
					<Quiz 
						areaindex={this.state.areaindex}
						questionindex={this.state.questionindex}
						quiz={this.state.quiz}
						answers={this.state.answers}
						totquestions={this.state.totquestions}
						showModal={this.state.showModal}

						handleClickNext={this.handleClickNext}
						handleClickBack={this.handleClickBack}
						handleClickAnswer={this.handleClickAnswer}
						
						openModal={this.openModal}
						closeModal={this.closeModal}
						displayAlert={this.state.displayAlert}
					/>);
			} else if (this.state.phase === 2) {
				quizPhase = (
					<FormsCompany 
						nome={this.state.nome}
						cognome={this.state.cognome}
						email={this.state.email}
						repeatEmail={this.state.repeatEmail}
						password={this.state.password}
						repeatPassword={this.state.repeatPassword}
						privacy={this.state.privacy}

						handleChangeForm={this.handleChangeForm}
						handleSubmitForms={this.handleSubmitForms}
						displayError={this.state.displayError}
						errorMessage={this.state.errorMessage}
					/>);
			} else if (this.state.phase === 3) {
				quizPhase = (
					<Email 
						email={this.state.email}
						pin={this.state.pin}
						verifyLoading={this.state.verifyLoading}

						handleChangeForm={this.handleChangeForm}
						handleVerifyPin={this.handleVerifyPin}
						displayError={this.state.displayError}
						errorMessage={this.state.errorMessage}/>
				);
			}
		}
		
		return (
			<div className="QuizController"> 
				{quizPhase} 
			</div>
		);
	}

}

export default QuizController;

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}