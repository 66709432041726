import React from 'react';

import {Container, Row, Col} from 'react-bootstrap';

import uomo from '../../assets/images/uomo.png';
import donna from '../../assets/images/donna.png';
import brain from '../../assets/images/brain.png';

import './Home.css';

const home = ( props ) => {

    return (
	   	<Container className="Home">
			<Row>
			    
			    <Col xs="12" md="4">
			    	<h3> Per i dipendenti </h3>
			    	<p> Fai il quiz e testa le tue<br></br> <b>competenze lavorative</b></p>
			    	<button onClick={() => props.history.push("quiz")} id="dipendente-btn"> 
			    		<img src={donna} alt="Icona Donna"/>
			    		<label>Iniziamo</label>
			    	</button>
			    </Col>

			    <Col md="4" className="d-none d-sm-block">
			    	<img src={brain} alt="Icona Superman" id="brain"/>
			    </Col>
			    
			    <Col xs="12" md="4">
			    	<h3> Per i manager </h3>
			    	<p> Conosci i tuoi dipendenti e migliora le <b>performance aziendali</b></p>
			    	<button onClick={() => props.history.push("perleaziende")} id="aziende-btn"> 
			    		<img src={uomo} alt="Icona Uomo"/>
			    		<label>Iscriviti</label>
			    	</button>
			    </Col>

			</Row>
		</Container>
    );
}

export default home;