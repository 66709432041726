import React from 'react';
import {Container, Row, Col, Form, Alert} from 'react-bootstrap';
import './Forms.css';

const forms = ( props ) => {

	let disableSubmit = true;
	if (
		props.nome
		&& props.cognome
		&& props.email
		&& props.repeatEmail
		&& props.password
		&& props.repeatPassword
		&& props.privacy
	) {
		disableSubmit= false;
	}

	let error = null;
	if (props.displayError) {
			error = <Alert variant="danger"> {props.errorMessage}</Alert>
		}

	const privacyLabel = <p>Ho letto e accetto l'<a href="/privacy" target="_blank"> Informativa sulla privacy </a></p>;

	return (
		<Container className="Forms">
			<Row>
				<Col>

					<h2> Questionario Completato! </h2>

					<p> I risultati sono pronti. Compila il seguente form per visualizzarli. </p>
					<p> * campi obbligatori </p>

					<Form onSubmit={props.handleSubmit}>

						<hr></hr>

						<span> I TUOI DATI </span>

						<Form.Group>
							<Form.Label>Nome*</Form.Label>
						    <Form.Control id="nome" type="text" placeholder="" value={props.nome} onChange={props.handleChangeForm}/>
						</Form.Group>

						<Form.Group>
						    <Form.Label>Cognome*</Form.Label>
						    <Form.Control id="cognome" type="text" placeholder="" value={props.cognome} onChange={props.handleChangeForm}/>
						</Form.Group>

						<Form.Group>
							<Form.Label>Email*</Form.Label>
						    <Form.Control id="email" type="email" placeholder="" value={props.email} onChange={props.handleChangeForm}/>
						</Form.Group>

						<Form.Group>
						    <Form.Label>Ripeti Email*</Form.Label>
						    <Form.Control id="repeatEmail" type="email" placeholder="" value={props.repeatEmail} onChange={props.handleChangeForm}/>
						</Form.Group>

						
						<Form.Group>
							<Form.Label>Password*</Form.Label>
						    <Form.Control id="password" type="password" placeholder="" value={props.password} onChange={props.handleChangeForm}/>
						</Form.Group>

						<Form.Group>
						    <Form.Label>Ripeti Password*</Form.Label>
						    <Form.Control id="repeatPassword" type="password" placeholder="" value={props.repeatPassword} onChange={props.handleChangeForm}/>
						</Form.Group>


						<Form.Group>
							<Form.Label>Numero di telefono</Form.Label>
						    <Form.Control id="telefono" type="number" placeholder="Esempio: 333 333333" value={props.telefono} onChange={props.handleChangeForm}/>
						</Form.Group>

						<Form.Group>
						    <Form.Label>CAP residenza</Form.Label>
						    <Form.Control id="cap_residenza" type="number" placeholder="Esempio: 93100" value={props.cap_residenza} onChange={props.handleChangeForm}/>
						</Form.Group>

						<hr></hr>

						<span> LA TUA AZIENDA </span>

						<Form.Group>
							<Form.Label>Nome azienda</Form.Label>
						    <Form.Control id="nome_azienda" type="text" placeholder="Esempio: Rossi Srl" value={props.nome_azienda} onChange={props.handleChangeForm}/>
						</Form.Group>

						<Form.Group>
							<Form.Label>CAP azienda</Form.Label>
							<Form.Control id="cap_azienda" type="number" placeholder="Esempio: 93100" value={props.cap_azienda} onChange={props.handleChangeForm}/>
						</Form.Group>

						<hr></hr>

						<Form.Group>
    						<Form.Check id="privacy" type="checkbox" label={privacyLabel} onChange={props.handleChangeForm} checked={props.privacy}/>
  						</Form.Group>

						
						{error}

						<button className="ds-button" onClick={props.handleSubmitForms} type="submit" disabled={disableSubmit}>
							Invia
						</button>
					</Form>

				</Col>
			</Row>
		</Container>
	);

}

export default forms;